import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'
import Navbar from '../inc/Navbar';
import { Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
// import PaginationOld from '../pages/PaginationOld';

import ScrollToTop from "react-scroll-to-top";
import "./Achievement.css";

import {
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
  FacebookIcon, TwitterShareButton, EmailShareButton, EmailIcon,

  LinkedinShareButton,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";




function Achievement() {
  const [setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPerPage, setShowPerPage] = useState(987);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const [searchTerm, setsearchTerm] = useState("");
  const [achievements, setAchievements] = useState([])
  const navigate = useNavigate()

  const achievementsCollectionRef = collection(db, "achievements");
  useEffect(() => {

    const getAchievements = async () => {
      const data = await getDocs(achievementsCollectionRef);
      setAchievements(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    getAchievements()
  }, [])
  return (

    <>
      <Navbar />

      <br />
      <br />
            <br />
      <ScrollToTop smooth top="100" color="darkBlue" />



      <div className="Achievementstory">
        <center>
          <h1><b>BHOLEBABA TOURISM ACHIEVEMENT</b></h1><br />
          <h3>Lava and Dooars Trip</h3>
        </center>
      </div>
      <br />
      <div className="album py-1">

        <div className="container">

          <div className="row">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li style={{ color: "darkBlue" }} className="breadcrumb-item"><Link style={{ color: "darkBlue" }} to="/" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                  <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                </svg> Back</Link></li>
                <li style={{ color: "darkBlue" }} className="breadcrumb-item"><Link style={{ color: "darkBlue" }} to="/Standard" href="#">Satndard</Link></li>
                <li style={{ color: "darkBlue" }} className="breadcrumb-item"><Link style={{ color: "darkBlue" }} to="#" href="#">Deluxe</Link></li>
                <li style={{ color: "darkBlue" }} className="breadcrumb-item"><Link style={{ color: "darkBlue" }} to="#" href="#">Super Deluxe</Link></li>



                <li className="breadcrumb-item active" aria-current="page">Our Achievement</li>

              </ol>
            </nav>
          </div>
        </div>
      </div>


      <div className="album py-2">

        <div className="container">

          <div className="row">

            <div className="col-md-6">

              <div className="card-body">
                <input type="text" className="form-control" placeholder="Search" name="location"
                  onChange={(e) => {
                    setsearchTerm(e.target.value);
                  }}
                />
                <div className="d-flex justify-content-between align-items-center">




                </div>


              </div>


            </div>

          </div>

        </div>
      </div>
      <div className="album py-4">

        <div className="container">

          <div className="row">
            {achievements.filter((val) => {
              if (setsearchTerm === "") {
                return val;
              } else if (
                val.title.toLowerCase().includes(searchTerm.toLowerCase())




              ) {
                return val;
              }
            }).slice(pagination.start, pagination.end).map((bholebabatourismachievements) => {
              return (
                <div className="col-md-4 mt-8" >
                  <div className="card mb-4 ">
                    <img className="card-img-top" src={bholebabatourismachievements.img} alt="bholebaba-tourism-achivement" />
                    <div className="card-body">
                  <center>
                      <h6 style={{color:"darkblue"}} className="card-text mb-3"> <b>{bholebabatourismachievements.title}</b></h6>
                      <p className="card-text mb-3">{bholebabatourismachievements.des}</p>
                      </center>
                      <div className="d-flex justify-content-between align-items-center">

                        <a href='tel: 9775914827' className="buttonbook mb-3">Call Book</a>
                        <ReactWhatsapp number="+91 9831964901" className="buttonbook mb-3" message="I am Interested"> Whatsapp Inquiry</ReactWhatsapp>

                      </div>
                      <button type="button" className="colorbacks me-1">
                        Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.306l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button>  <FacebookShareButton url="https://www.bholebabatourism.com/Achievement" className="me-2" quote={"Bholebaba Tourism"} hastag={"#WebApp"}
                      >
                        <FacebookIcon size={32} round={true} />
                      </FacebookShareButton>

                      <  WhatsappShareButton url="https://www.bholebabatourism.com/Achievement" className="me-2" quote={"Bholebaba Tourism"} hastag={"#WebApp"}
                      >
                        <WhatsappIcon size={32} round={true} />
                      </ WhatsappShareButton>

                      <  TwitterShareButton url="https://www.bholebabatourism.com/Achievement" className="me-2" quote={"Bholebaba Tourism"} hastag={"#WebApp"}
                      >
                        <TwitterIcon size={32} round={true} />
                      </ TwitterShareButton>

                      <  LinkedinShareButton url="https://www.bholebabatourism.com/Achievement" className="me-2" quote={"Bholebaba Tourism"} hastag={"#WebApp"}
                      >
                        <  LinkedinIcon size={32} round={true} />
                      </ LinkedinShareButton>

                      <  EmailShareButton url="https://www.bholebabatourism.com/Achievement" quote={"Bholebaba Tourism"} hastag={"#WebApp"}
                      >
                        <  EmailIcon size={32} round={true} />
                      </ EmailShareButton>

                    </div>
                  </div>

                </div>
              )
            })}
          </div>

         
        </div>
      </div>

    </>

  );
}

export default Achievement;


