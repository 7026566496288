import React from 'react'
import Navbar from '../inc/Navbar'
import AddPackage from '../AddPackage';
import Nepal from "../images/P-nepal.jpg";
import Gujrat from "../images/gujrat-image.jpg";
import HARIDWAR from "../images/HARIDWAR & MUSUORI.jpg";
import Rajasthan from "../images/rajasthan-heritage-tour.jpg";
import Darjeeling from "../images/Darjeeling.jpg";
import { Link } from "react-router-dom";

function Deluxe() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="album py-2">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/Standard" href="#">Standard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Deluxe </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="album py-2">
                <div className="container">
                    <div className='row'>
                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <img src={Nepal} className="card-img-top" alt="nepal_tour" />
                                <div className="card-body">
                                    <h2 className='mb-3' style={{ color: "maroon" }}>Deluxe Package Details</h2>
                                    <h3 className="card-title">NEPAL</h3>
                                    <p className="card-text">10 NIGHTS 11 DAYS (Hwh To Hwh)  19500 With 3ac train . Tkt 2
                                        Kathmamdu 3 N , pokhra 2N, chitwan 1N, Birganj 1 N</p>
                                    <button type="button" className="buttonbook" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        Book Now
                                    </button>


                                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel"></h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <AddPackage />
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <img src={Gujrat} className="card-img-top" alt="Gujrat_tour" />
                                <div className="card-body">
                                    <h2 className='mb-3' style={{ color: "maroon" }}>Deluxe Package Details</h2>
                                    <h3 className="card-title">GUJRAT</h3>
                                    <p className="card-text">12 NIGHTS 13 DAYS 
(HWH TO HWH) 19500 WITH SL TRAIN TKT | Ahmedabad 2 N, SOMNATH 2 N, DWARKA 2 N, VARODRA -1</p>
                                    <button type="button" className="buttonbook" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        Book Now
                                    </button>


                                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel"></h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <AddPackage />
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <img src={HARIDWAR} className="card-img-top" alt="HARIDWAR_tour" />
                                <div className="card-body">
                                    <h2 className='mb-3' style={{ color: "maroon" }}>Deluxe Package Details</h2>
                                    <h3 className="card-title">HARIDWAR & MUSUORI</h3>
                                    <p className="card-text">8 NIGHT 9 DAYS ( HWH TO HWH) 13500
Haridwar 4 N, Musori 1 N</p>
<br/>
                                    <button type="button" className="buttonbook" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        Book Now
                                    </button>


                                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel"></h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <AddPackage />
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <img src={Rajasthan} className="card-img-top" alt="Rajasthan_tour" />
                                <div className="card-body">
                                    <h2 className='mb-3' style={{ color: "maroon" }}>Deluxe Package Details</h2>
                                    <h3 className="card-title">RAJASTHAN </h3>
                                    <p className="card-text">14 NIGHT 15 DAYS  
HWH TO HWH 3 AC TKT 
29 500
Jaipur 2 n, Puskar/ ajmir 1 Udaypur 2 ( chitor), mount abu 2 , jaisalmir 2 , jodhpur 2 ,  bikaner 1 
</p>
                                    <button type="button" className="buttonbook" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        Book Now
                                    </button>


                                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel"></h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <AddPackage />
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card" >
                                <img src={Darjeeling} className="card-img-top" alt="Darjeeling_tour" />
                                <div className="card-body">
                                    <h2 className='mb-3' style={{ color: "maroon" }}>Deluxe Package Details</h2>
                                    <h4 className="card-title">Darjeeling </h4>
                                    <p className="card-text">3 N 4 days. </p>
                                    <p className="card-text">Price:- @7600/-</p>
<br/>
                                    <button type="button" className="buttonbook" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        Book Now
                                    </button>


                                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel"></h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <AddPackage />
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    )
}

export default Deluxe