import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'

import ReactWhatsapp from "react-whatsapp";





function Package() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(21);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [allpackage, setAllpackage] = useState([])
    const navigate = useNavigate()

    const allpackageCollectionRef = collection(db, "Allpackage");
    useEffect(() => {

        const getAllpackage = async () => {
            const data = await getDocs(allpackageCollectionRef);
            setAllpackage(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getAllpackage()
    }, [])
    return (

        <>



            <div className="album py-2">

                <div className="container">

                    <div className="row">

                        <div className="col-md-6">

                            <div className="card-body">
                                <input type="text" className="form-control" placeholder="Search" name="location"
                                    onChange={(e) => {
                                        setsearchTerm(e.target.value);
                                    }}
                                />
                                <div className="d-flex justify-content-between align-items-center">

                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        {allpackage.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((bholebabatourism) => {
                            return (

                                <div className="row g-0">
                                    <div className="col-md-5 mb-4 mt-3">
                                        <img src={bholebabatourism.img} className="img-fluid rounded-start me-0 mb-2" alt="Arunachaltourism" />
                                        <center>
                                        <h5>Price:- {bholebabatourism.rs}</h5>
                                        </center>
                                    </div>
                                    
                                    <div className="col-md-7">
                                        <div className="card-body">
                                            <h4 className="card-title ">{bholebabatourism.title} <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
                                                <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                                                <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                                            </svg></h4>
                                            <p className="card-text "> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                            </svg> {bholebabatourism.location}</p>
                                            <p className="card-text ">  Organized by Bholebaba Tourism</p>
                                            <h5 className="card-text me-0"><small className="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-calendar-check-fill" viewBox="0 0 16 16">
                                                <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2m-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                                            </svg> {bholebabatourism.day} Days</small></h5>
                                            <a href='tel: +91 9775914827' className='buttonbook me-2'>Call Now</a>
                                            <ReactWhatsapp number="+91 9775914827" className="buttonbook " message="I am Interested"> WhatsApp </ReactWhatsapp>

                                        </div>
                                    </div>
                                </div>


                            )
                        })}
                    </div>



                </div>
            </div>

        </>

    );
}

export default Package;


