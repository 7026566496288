import React from 'react'

function Map() {
  return (
    <div>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
       
 <iframe  className="iframe-fluid"   height="425" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=ISHAAN%20NETWORK,%20XJHC+3XR,%20Thana%20Rd,%20Karimpur,%20West%20Bengal%20741152%20+(ISHAAN%20NETWORK,%20XJHC+3XR,%20Thana%20Rd,%20Karimpur,%20West%20Bengal%20741152)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> 



</div>

</div>
</div>
    </div>
  )
}

export default Map