
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyDhl_WhJC_9Twx7K_V6HglPwmmvWFeIFRQ",
    authDomain: "bholebaba-tourism.firebaseapp.com",
    projectId: "bholebaba-tourism",
    storageBucket: "bholebaba-tourism.appspot.com",
    messagingSenderId: "1044419027844",
    appId: "1:1044419027844:web:d835638af4853bb4d2b3d1",
    measurementId: "G-E0247L0NPL"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;