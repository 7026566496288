import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'




function Notice() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [notice, setNotice] = useState([])
      const navigate = useNavigate()
  
      const noticeCollectionRef = collection(db, "Notice");
      useEffect(() => {
  
          const getNotice = async () => {
              const data = await getDocs(noticeCollectionRef);
              setNotice(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getNotice()
      }, [])
    return (
      
<>







            {notice.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } {
        return val;
      }
    }).slice(pagination.start, pagination.end).map((bholebabatourism) => { return (
       
            
                      
                       
                            <div className="card-body">
                                <marquee>
                                <h6 style={{color:"maroon"}} className="card-text"> {bholebabatourism.title}</h6>
                                </marquee>
                              



                        
                                {/* <h5 style={{color: "Darkblue"}}className="card-text"> ₹{website.saleprice} </h5>
                                <p style={{color: "Gray"}}className="card-text mb-2"> M.R.P.: <del> ₹{website.delete}</del> </p> */}
                               

                               
                    
                              
                            </div>
                           
                                )})} 
                            
               
                           

</>
        
        );
    }
    
    export default Notice;