import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'








function Achievement() {
  const [setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPerPage, setShowPerPage] = useState(58);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const [searchTerm, setsearchTerm] = useState("");
  const [photogallery, setPhotogallery] = useState([])
  const navigate = useNavigate()

  const photogalleryCollectionRef = collection(db, "Photogallery");
  useEffect(() => {

    const getPhotogallery = async () => {
      const data = await getDocs(photogalleryCollectionRef);
      setPhotogallery(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    getPhotogallery()
  }, [])
  return (

    <>

  

      <div className="album py-4">

        <div className="container">

          <div className="row">
            {photogallery.filter((val) => {
              if (setsearchTerm === "") {
                return val;
              } else if (
                val.img.toLowerCase().includes(searchTerm.toLowerCase())




              ) {
                return val;
              }
            }).slice(pagination.start, pagination.end).map((bholebabatourismachievements) => {
              return (
                <div className="col-md-4 mt-8" >
                  <div className="card mb-4 ">
                    <img className="card-img-top" src={bholebabatourismachievements.img} alt="bholebaba-tourism-achivement" />
              
                  </div>

                </div>
              )
            })}
          </div>



        </div>
      </div>

    </>

  );
}

export default Achievement;


