
import React from 'react'
import ReactWhatsapp from "react-whatsapp";
import Hotel from "../images/hotel-image.jpg";
import Air from "../images/air.png";
import train from "../images/train.jpg";

function Others() {
    return (
        <>
            <div className="sticky-top">
                <div className="album py-0">

                    <div className="container">

                        <div className="row">
                            <div className=''>

                                <div className="card mb-4 border-White">

                                    <img className="card-img-top" src={Hotel} alt="Hotel" />
                                    <div className="card-body">

                                        <h6 className="card-text mb-2"> <b>All Over India</b></h6>

                                        <p className="card-text mb-2">India's Trusteble & Secure Hotel Partner.</p>
                                        <p className="card-text mb-2">5 Star Hotel</p>

                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <a href="tel: +91 9775914827" className="buttonbooks other others btn-sm mb-2 me-2">  Call</a>
                                            <ReactWhatsapp number="+91 9775914827" className="buttonbooks others btn-sm mb-2" message="I am Interested"> WhatsApp</ReactWhatsapp>

                                        </div>
                                    </div>
                                </div>

                                
                                <div className="card mb-4 border-White">

                                    <img className="card-img-top" src={Air} alt="Air" />
                                    <div className="card-body">

                                        <h6 className="card-text mb-2"> <b>All Over India Air</b></h6>

                                        <p className="card-text mb-2">DOMESTIC & INTERNATIONAL AIR TICKET BOOKING</p>
                                   

                                        <div className="d-flex justify-content-between align-items-center mb-0">
                                            <a href="tel: +91 9831964901" className="buttonbooks other others btn-sm mb-2 me-2">Call </a>
                                            <ReactWhatsapp number="+91 9831964901" className="buttonbooks others btn-sm mb-2" message="I am Interested"> WhatsApp</ReactWhatsapp>

                                        </div>
                                    </div>
                                </div>
                                
                                <div className="card mb-4 border-White">

                                    <img className="card-img-top" src={train} alt="train" />
                                    <div className="card-body">

                                        <h6 className="card-text mb-2"> <b>Railway All India Booking</b></h6>

                                        <p className="card-text mb-2">Railway Reservation</p>
                                      

                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <a href="tel: +91 9831964901" className="buttonbooks other others btn-sm mb-2 me-2">Call</a>
                                            <ReactWhatsapp number="+91 9831964901" className="buttonbooks others btn-sm mb-2" message="I am Interested"> WhatsApp</ReactWhatsapp>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Others