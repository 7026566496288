import React from 'react'
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import videogallery from "../images/tour.mp4";
import Videoga from "../images/video g1.mp4";


function Videog() {
    return (
        <div>




            <div className="album py-4">
                <div className="container">
                    <div className="row ">
                        <div className="col-md-6 mt-2">

                            <Video Play loop onCanPlayThrough={() => {
                                console.log('video play')
                            }}>
                                <source src={videogallery} type="video/webm" />
                            </Video>
                        </div>


                        <div className="col-md-6 mt-2">
                            <Video Play muted loop onCanPlayThrough={() => {
                                console.log('video play')
                            }}>
                                <source src={Videoga} type="video/webm" />
                            </Video>
                            <center>
                            <h5 style={{color:"darkblue"}} className='mt-2'>Ghoom Railway Station Video Gallery</h5></center>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default Videog