
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Main from "./components/pages/Main";
import Contacts from "./components/pages/Contacts";
import Footer from "./components/inc/Footer";
import Achievement from './components/pages/Achievement';
import Journey from './components/pages/Jaurney';
import Standard from './components/pages/Standard';
import Deluxe from './components/pages/Deluxe';
import Gallery from './components/pages/Gallery';
import Payment from './components/pages/Payment';
import About from './components/pages/About';

function App() {
  return (
    <Router>
    
    <div>

     
      
      <Routes>

      <Route axact path="/" element={<Main/>}/>
      <Route axact path="/Contacts" element={<Contacts/>}/>
      <Route axact path="/Achievement" element={<Achievement/>}/>
      <Route axact path="/up-coming-journey" element={<Journey/>}/>
      <Route axact path="/Standard" element={<Standard/>}/>
      <Route axact path="/Deluxe" element={<Deluxe/>}/>
      <Route axact path="/gallery" element={<Gallery/>}/>
      <Route axact path="/Payment" element={<Payment/>}/>
      <Route axact path="/about" element={<About/>}/>
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
