import React from 'react'
import Navbar from '../inc/Navbar'
import "./Main.css";
import Slider from '../inc/Slider';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ScrollToTop from "react-scroll-to-top";
// import Carousel from 'react-grid-carousel'
import Sikkimimage from "../images/sikkimimage.jpg";
import Ajodhya from "../images/ajodhya_ram_temple.jpg";
import { Link } from "react-router-dom";

import Package from './Package';
import Others from './Others';
import Darjeeling from "../images/darjeeling-image.jpg";
import Puri from "../images/puri.jpg";
import Nepal from "../images/nepal.jpg";
import jammu from "../images/jammukas.jpg";
import Ramoji from "../images/ramoji.jpg";
import Notice from './Notice';
import Map from "../pages/Map";


// import AddPackage from '../AddPackage';

function Main() {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <Slider />
            <ScrollToTop smooth top="100" color="dark" />
            <div className='colorback'>
                <div className="container">

                    <div className="row">

                        <div className="col-md-8">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="colorbacks">
                                    <div className="card-header"><center><b>NEWS</b></center></div>

                                </div>


                                <div className="col-md-12 mt-2">
                                    <marquee> <h5 style={{ color: "white" }} >Bholebaba Trourism, Karimpur, Nadia, Best Tourism in West Bengal</h5></marquee>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="album py-3">

                <div className="container">

                    <div className="row">

                        <div className="col-md-4 ">
                            <div className="card text-dark bg-light mb-3">
                                <div style={{ color: "darkBlue" }} className="card-header"><center><b>STANDARD PACKAGE</b></center></div>
                                <div className="card-body">
                                    <h5 className="card-title"> 4 Nights/5 Days</h5>
                                    <p className="card-text">Upto 2 Stars, Meals, Sightseeing, Airport Transfer, Hotel</p>

                                    <center>
                                        <Link to="/Standard">
                                            <button className='india1'>Tour Details</button>
                                        </Link>
                                    </center>
                                    {/* <center>

                                        <button type="button" className="buttonbook" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            Book Now
                                        </button>


                                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h6 style={{ color: "maroon" }} className="modal-title" id="exampleModalLabel">www.bholebabatourism.com</h6>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <AddPackage />
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="colorbacks" data-bs-dismiss="modal">Close</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </center> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-8">
                            <div className="card text-dark bg-light mb-3">
                                <div style={{ color: "darkBlue" }} className="card-header"><center><b>DELUXE PACKAGE</b></center></div>
                                <div className="card-body">
                                    <h5 className="card-title">4 Nights/5 Days</h5>
                                    <p className="card-text ">Upto 2 Stars, Meals, Sightseeing, Transfers</p>
                                    <br />
                                    <center>
                                        <Link to="/Deluxe">
                                            <button className='btn-grad20'>Tour Details</button>
                                        </Link>
                                    </center>
                                    {/* <center>

                                        <button type="button" className="buttonbook" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            Book Now
                                        </button>


                                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h6 style={{ color: "maroon" }} className="modal-title" id="exampleModalLabel">www.bholebabatourism.com</h6>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <AddPackage />
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="colorbacks" data-bs-dismiss="modal">Close</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </center> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-8">
                            <div className="card text-dark bg-light mb-3">
                                <div style={{ color: "darkBlue" }} className="card-header"><center><b>SUPER DELUXE PACKAGE</b></center></div>
                                <div className="card-body">
                                    <h5 className="card-title">5 Nights/6 Days</h5>
                                    <p className="card-text">Upto 5 Stars, Meals, Sightseeing, Airport Pickup-Drop, Hotel, Private Cab</p>

                                    <center>
                                        <button className='india3'>Tour Details</button>
                                    </center>
                                    {/* <center>

                                        <button type="button" className="buttonbook" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            Book Now
                                        </button>


                                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h6 style={{ color: "maroon" }} className="modal-title" id="exampleModalLabel">www.bholebabatourism.com</h6>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <AddPackage />
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="colorbacks" data-bs-dismiss="modal">Close</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </center> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="container">
                <div className="card text-dark bg-light mb-3" >
                    <div style={{color:"maroon"}} className="card-header"><center>NOTICE <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
  <span class="visually-hidden">Loading...</span></center></div>
                    <div className="card-body">
                        <Notice/>
                        
                    </div>
                </div>

            </div>
            <br />

            <div className="album py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="card mb-4" >
                                <Package />
                            </div>
                        </div>
                        <div className="col-md-3 mt-0">
                            <Others />

                            {/* <div class="sticky-top" top="100">
                                <div className="card">
                                    <img src={Hotel} className="card-img-top" alt="Hotel" />
                                    <div className="card-body">
                                        <h5 className="card-title">All Over India</h5>
                                        <p className="card-text mb-3">India's Trusteble & Secure Hotel Partner.</p>
                                        <p className="card-text">5 Start Hotel</p>
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <a href="tel: +91 9775914827" className="buttonbooks other others btn-sm mb-2 me-2">     Call Now</a>
                                            <ReactWhatsapp number="+91 9775914827" className="buttonbooks others btn-sm mb-2" message="I am Interested"> WhatsApp</ReactWhatsapp>

                                        </div>

                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>


            <div className="besttourismnadia">
                <center>
                    <h1><b>BHOLEBABA TOURISM</b></h1>
                    <p><small>Trusted compnay for travel accross India</small></p>
                    <a href='tel: +91 9775914827' className='buttonbook'>CALL NOW</a>

                </center>
            </div>
            <br />
            <div className="album py-0">

                <div className="container">
                    <h5><b><button className='tranding btn-sm'>TRANDING</button> Holiday Destinations</b></h5>
                </div>
            </div>
            <div className="album py-1">

                <div className="container">

                    <div className="row">

                        <Carousel responsive={responsive}>
                            <div className="card border mb-3 me-3" >
                                <img className="card-img-top" src={Sikkimimage} alt="Sikkimimage" />
                                <div className="card-body text">
                                    <p className="card-title mb-0">Sikkim</p>
                                    <p className="card-text"><span><small> 25+Package</small></span></p>




                                </div>
                            </div>
                            <div className="card border mb-3 me-3" >
                                <img className="card-img-top" src={Ajodhya} alt="Ajodhya" />
                                <div className="card-body text">
                                    <p className="card-title mb-0">Ajodhya </p>
                                    <p className="card-text"><span><small> 20+Package</small></span></p>
                                </div>
                            </div>
                            <div className="card border mb-3 me-3" >
                                <img className="card-img-top" src={Darjeeling} alt="Darjeeling" />
                                <div className="card-body text">
                                    <p className="card-title mb-0">Darjeeling</p>
                                    <p className="card-text"><span><small> 15+Package</small></span></p>
                                </div>
                            </div>
                            <div className="card border mb-3 me-3" >
                                <img className="card-img-top" src={Puri} alt="Puri" />
                                <div className="card-body text">
                                    <p className="card-title mb-0">Puri</p>
                                    <p className="card-text"><span><small> 20+Package</small></span></p>
                                </div>
                            </div>
                            <div className="card border mb-3 me-3" >
                                <img className="card-img-top" src={Nepal} alt="Nepal" />
                                <div className="card-body text">
                                    <p className="card-title mb-0">Nepal</p>
                                    <p className="card-text"><span><small> 18+Package</small></span></p>
                                </div>
                            </div>
                            <div className="card border mb-3 me-3" >
                                <img className="card-img-top" src={jammu} alt="jammu" />
                                <div className="card-body text">
                                    <p className="card-title mb-0">Jammu</p>
                                    <p className="card-text"><span><small> 28+Package</small></span></p>
                                </div>
                            </div>
                            <div className="card border mb-3 me-3" >
                                <img className="card-img-top" src={Ramoji} alt="Ramoji" />
                                <div className="card-body text">
                                    <p className="card-title mb-0">Ramoji Film City</p>
                                    <p className="card-text"><span><small> 15+Package</small></span></p>
                                </div>
                            </div>
                        </Carousel>
                    </div>
                </div>

            </div>
            <br />

<Map/>


            <br />
        </div>
    )
}

export default Main