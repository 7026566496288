
import React from 'react'
import Navbar from '../inc/Navbar';
import aboutus from "../images/office.jpg";
import {Link} from "react-router-dom";

function About() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />


            <div className='album py-2'>
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">About Us</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div className='album py-2'>
                <div className="container">
                    <div className="row">

                        <div className="col-md-6">

                            <img src={aboutus} className="card-img-top" alt="aboutus" />
                            <div className="card-body">
                                <h5 className="card-title mt-3"><center>OFFICE</center></h5>


                            </div>
                        </div>
                        <div className="col-md-6">

                            <div className="card-body">
                                <h5 className="card-title mb-2">Vholebaba Tourism</h5>
                                <p className="card-text">State Bank Beside, Karimpur

                                    Pin-741152, Dist- Nadia

                                    West Bengal | India
                                </p>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
<br/>
        </div>
    )
}

export default About
