import React from 'react'
import Navbar from '../inc/Navbar'
import Photog from './Photog';
import Videog from './Videog';
import ScrollToTop from "react-scroll-to-top"

function Gallery() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br />
            <br />
        <ScrollToTop smooth top="100" color="dark"/>
        
        <div className="album py-2">
                <div className="container">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item" role="presentation">
    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Photo Gallery</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Video Gallery </button>  </li>

</ul>
<div className="tab-content" id="myTabContent">
  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
    <br/>
    <Photog/>
  </div>
  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
    <br/>
    <Videog/>
  </div>

</div>
</div>
</div>
    </div>
  )
}

export default Gallery