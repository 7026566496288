import React from 'react'
// import Bholebabatourism from "../images/SIKKIM.jpg";
// import TOURINDIA from "../images/GOA.jpg";
import Upcomingjourney from "../images/JAMMUkashmir.jpg";

import Ourgallery from "../images/NEW_BANNER_VHOLEBABA.jpg";
import Galleryvholebaba from "../images/gallery_vholebaba.jpg";
import clean from "../images/Mawlynnong.jpg";
import Puriimage from "../images/PURI-IMG.jpg";

function Slider() {
  return (
    <div>
      <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>

          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>

        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <a href='tel: 9775914827'>
              <img src={Ourgallery} className="d-block w-100" alt="Upcomingjourney" />
            </a>
          </div>
          <div className="carousel-item">
            <img src={Galleryvholebaba} className="d-block w-100" alt="Bholebabatourism" />
          </div>
          <div className="carousel-item">
            <img src={Upcomingjourney} className="d-block w-100" alt="Bholebabatourism" />
          </div>
          <div className="carousel-item">
            <img src={clean} className="d-block w-100" alt="Mawlynnong" />
          </div>
          <div className="carousel-item">
            <img src={Puriimage} className="d-block w-100" alt="Puriimage" />
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  )
}

export default Slider