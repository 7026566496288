import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'
import Navbar from '../inc/Navbar';
import { Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";

import ScrollToTop from "react-scroll-to-top";
import "./Achievement.css";

import {
    FacebookShareButton, WhatsappShareButton, WhatsappIcon,
    FacebookIcon, TwitterShareButton, EmailShareButton, EmailIcon,

    LinkedinShareButton,
    LinkedinIcon,
    TwitterIcon,
} from "react-share";




function Journey() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(51);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [journey, setJourney] = useState([])
    const navigate = useNavigate()

    const journeyCollectionRef = collection(db, "UpComingJourney");
    useEffect(() => {

        const getJourney = async () => {
            const data = await getDocs(journeyCollectionRef);
            setJourney(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getJourney()
    }, [])
    return (

        <>
            <Navbar />

            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="darkBlue" />



        
            <br />
          
            <div className='tourismpackagebooknadia'>
         <center>
                <h5 className='mt-3 mb-3'>Up Comming Journey</h5>
                </center>
               
            <div className="album py-1 mt-0">

                <div className="container">

                    <div className="row">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li style={{ color: "darkBlue" }} className="breadcrumb-item"><Link style={{ color: "darkBlue" }} to="/" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                    <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                </svg> Back</Link></li>
                                <li style={{ color: "darkBlue" }} className="breadcrumb-item"><Link style={{ color: "darkBlue" }} to="/Standard" href="#">Satndard</Link></li>
                                <li style={{ color: "darkBlue" }} className="breadcrumb-item"><Link style={{ color: "darkBlue" }} to="#" href="#">Deluxe</Link></li>
                                <li style={{ color: "darkBlue" }} className="breadcrumb-item"><Link style={{ color: "darkBlue" }} to="#" href="#">Super Deluxe</Link></li>
                                <li style={{ color: "darkBlue" }} className="breadcrumb-item"><Link style={{ color: "darkBlue" }} to="/Achievement" >Achievement</Link></li>



                                <li className="breadcrumb-item active" aria-current="page">Up-Coming journey</li>

                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            </div>
            <br/>

            <div className="album py-1">

                <div className="container">

                    <div className="row">

                        <div className="col-md-6">

                            <div className="card-body">
                                <input type="text" className="form-control" placeholder="Search" name="location"
                                    onChange={(e) => {
                                        setsearchTerm(e.target.value);
                                    }}
                                />
                                <div className="d-flex justify-content-between align-items-center">




                                </div>


                            </div>


                        </div>

                    </div>

                </div>
            </div>
            <div className="album py-1">

                <div className="container">

                    <div className="row">
                        {journey.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                val.des.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((bholebabatourismjourney) => {
                            return (
                                <div className="col-md-6 mt-4" >
                                    <div className="card mb-4">
                                    <div class="card-header"><center>UP COMING JOURNEY</center></div>
                                        <div className="card-body">

                                            <h6 style={{color:"maroon"}} className="card-text"> <b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar2-check" viewBox="0 0 16 16">
  <path d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z"/>
  <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5z"/>
</svg> {bholebabatourismjourney.title}</b></h6>
                                            <p className="card-text mb-3"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
</svg> {bholebabatourismjourney.des}   <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
  <span class="visually-hidden">Loading...</span></p>
                                            <br/>
                                            <div className="d-flex justify-content-between align-items-center">

                                                <a href='tel: 9775914827' className="buttonjourney mb-3">Call Book</a>
                                                <ReactWhatsapp number="+91 9831964901" className="buttonjourney mb-3" message="I am Interested"> Whatsapp Inquiry</ReactWhatsapp>

                                            </div>
                                            
                                            <button type="button" className="colorbacks me-1">
                                                Share  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                                                    <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.306l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                                                </svg>
                                            </button>  < FacebookShareButton url="https://www.bholebabatourism.com/up-coming-journey" className="me-2" quote={"Bholebaba Tourism"} hastag={"#WebApp"}
                                            >
                                                <FacebookIcon size={30} round={true} />
                                            </FacebookShareButton>

                                            <  WhatsappShareButton url="https://www.bholebabatourism.com/up-coming-journey" className="me-2" quote={"Bholebaba Tourism"} hastag={"#WebApp"}
                                            >
                                                <WhatsappIcon size={30} round={true} />
                                            </ WhatsappShareButton>

                                            <  TwitterShareButton url="https://www.bholebabatourism.com/up-coming-journey" className="me-2" quote={"Bholebaba Tourism"} hastag={"#WebApp"}
                                            >
                                                <TwitterIcon size={30} round={true} />
                                            </ TwitterShareButton>

                                            <  LinkedinShareButton url="https://www.bholebabatourism.com/up-coming-journey" className="me-2" quote={"Bholebaba Tourism"} hastag={"#WebApp"}
                                            >
                                                <  LinkedinIcon size={30} round={true} />
                                            </ LinkedinShareButton>

                                            <  EmailShareButton url="https://www.bholebabatourism.com/up-coming-journey" quote={"Bholebaba Tourism"} hastag={"#WebApp"}
                                            >
                                                <  EmailIcon size={30} round={true} />
                                            </ EmailShareButton>

                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>



                </div>
            </div>

        </>

    );
}

export default Journey;


