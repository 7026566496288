import React from 'react'
import Navbar from "../inc/Navbar";
import phonepe from "../images/qrcodephoneope.jpg"

function Payment() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className="album py-2">
                <div className="container">
                    <div className="card border-success mb-3" >
                        <div className="card-header bg-transparent border-success"><center>ACCOUNT DETAILS</center></div>
                        <div className="card-body text-dark">
                            <div className="container">

                                <div className="row">

                                    <div className="col-md-7">
                                    <img src={phonepe} className="card-img-top" alt="paymentvholebaba_tourism"/>

                                       
                                    </div>
                                    <div className="col-md-5 mt-4">
                                        <h5 className="card-title">MALOY SARKAR</h5>
                                        <p className="card-text mb-2">STATE BANK OF INDIA</p>
                                        <p className="card-text mb-2">SBIN0003735</p>
                                        <p className="card-text mb-2">37851265217</p>
                                        


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer bg-transparent border-success">
                            <marquee><h4 className='mt-2'>Vholebaba Tourism - Thank You!  Any Query Help Line No. <a href='tel: +91 9775914827'>+91 9775914827</a> </h4></marquee>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Payment