import React from 'react'
import Navbar from '../inc/Navbar'
import AddPackage from '../AddPackage';
import KEDARNATH from "../images/KEDARNATH & BADRINATH.png";
import Rajasthan from "../images/rajasthan-heritage-tour.jpg";
import Darjeeling from "../images/Darjeeling.jpg";
import { Link } from "react-router-dom";

function Standard() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="album py-2">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/Deluxe" href="#">Deluxe</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Standard </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="album py-2">
                <div className="container">
                    <div className='row'>
                        <div className="col-md-4">
                            <div className="card" >
                                <img src={KEDARNATH} className="card-img-top" alt="kedarnath_tour" />
                                <div className="card-body">
                                    <h2 className='mb-3' style={{ color: "maroon" }}>Standard Package Details</h2>
                                    <h4 className="card-title">KEDARNATH & BADRINATH </h4>
                                    <p style={{ color: "grey" }} className="card-text"> <small>13 NIGHT 14 DAYS Hwh to hwh SL. Tkt 20500
                                        HARIDWAR 2 N , UTTARKASI  1 N. KEDAR NATH 1 , BADRI 2 , SITAPUR 2 </small></p>
                                    <button type="button" className="buttonbook" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        Book Now
                                    </button>


                                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel"></h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <AddPackage />
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card" >
                                <img src={Rajasthan} className="card-img-top" alt="Rajasthan_tour" />
                                <div className="card-body">
                                    <h2 className='mb-3' style={{ color: "maroon" }}>Standard Package Details</h2>
                                    <h4 className="card-title">RAJASTHAN </h4>
                                    <p className="card-text">14 NIGHT 15 DAYS
                                        HWH TO HWH 3 AC TKT
                                        23 500

                                        Jaipur 2 n, Puskar/ ajmir 1
                                        Udaypur 2 ( chitor), mount abu 2 , jaisalmir 2 , jodhpur 2 ,  bikaner 1</p>
                                    <button type="button" className="buttonbook" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        Book Now
                                    </button>


                                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel"></h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <AddPackage />
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-4 ">
                            <div className="card" >
                                <img src={Darjeeling} className="card-img-top" alt="Darjeeling_tour" />
                                <div className="card-body">
                                    <h2 className='mb-3' style={{ color: "maroon" }}>Standard Package Details</h2>
                                    <h4 className="card-title">Darjeeling </h4>
                                    <p className="card-text">3 N 4 days. </p>
                                    <p className="card-text">Price:- @5700/-</p>
                                    <br />
                                    <button type="button" className="buttonbook" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        Book Now
                                    </button>


                                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel"></h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <AddPackage />
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default Standard