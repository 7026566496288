import React from 'react'
import {Link}  from "react-router-dom";
import "./Navbar.css";
import logo from "../images/logovholebaba.jpg";

function Navbar() {
  return (
    <div>
<div className='fixed-top'>
  <div className='vholebaba'>
  <div className="album py-0">

<div className="container">

  <div className="row ">

    <div className="col-md-5" >
      <div className="d-flex justify-content-between align-items-center">
  

      <a style={{color:"darkblue"}} href='tel: +91 9775914827'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg>+91 9775914827</a>


        
      

      </div>

    </div>

  
  </div>
</div>
</div>
  </div>
<nav className="navbar navbar-expand-lg navbar-white bg-white shadow">
  <div className="container-fluid">
    <Link to="/">
      <img src={logo}  height="40" width="180" alt='logo'/>
  
    </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/" className="buttonbooknav me-2 mb-1" aria-current="page">Home</Link>
        </li>
        <li className="nav-item">
          <Link to="/about" className="buttonbooknav me-2 mb-1" >About</Link>
        </li>
        {/* <li className="nav-item">
          <Link to="#" className="nav-link">About</Link>
        </li> */}
   
     
        <li className="nav-item dropdown">
          <a className=" buttonbooknav me-2 mb-1" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Super Package
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link to="/Standard" className="dropdown-item" href="#">Standard</Link></li>
            <li><Link to="/Deluxe" className="dropdown-item">Deluxe</Link></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href="#">Super Deluxe</a></li>
          </ul>
        </li>
        <li className="nav-item">
          <Link to="/Achievement" className=" buttonbooknav me-2 mb-1"  tabindex="-1" aria-disabled="true">Achievement</Link>
        </li>
        <li className="nav-item">
          <Link to="/up-coming-journey" className=" buttonbooknav me-2 mb-1"  tabindex="-1" aria-disabled="true">Up-Coming Journey</Link>
        </li>
        <li className="nav-item">
          <Link to="/gallery" className="buttonbooknav me-2 mb-1"  tabindex="-1" aria-disabled="true">Photo / Video Gallery</Link>
        </li>
        <li className="nav-item">
          <Link to="/Payment" className="buttonbooknav me-2 mb-1" >Payment</Link>
        </li>
        <li className="nav-item">
          <Link to="/Contacts" className="buttonbooknav " >Contact</Link>
        </li>
     
      </ul>
     
     
        {/* <a href='tel: 9775914827' className="buttonbooknav" type="submit">Call Now</a> */}
    
    </div>
  </div>
</nav>
</div>
    </div>
  )
}

export default Navbar